<template>
<div>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>cast</md-icon>
                </div>
                <h4 class="title">Edit KF Conference Info</h4>
                </md-card-header>
                <md-card-content>
                <md-table v-model="contents" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell v-if="item.number === 0" md-label="type">Screen</md-table-cell>
                        <md-table-cell v-else-if="item.number === 1" md-label="type">Banner_Left</md-table-cell>
                        <md-table-cell v-else md-label="type">Banner_Right</md-table-cell>
                        <md-table-cell md-label="Thumbnail(not required)">
                            <img class="item-thumb" :src="item.thumbnailUrl" />
                        </md-table-cell>
                        <md-table-cell md-label="Data Type">{{ item.dataType }}</md-table-cell>
                        <md-table-cell md-label="Title">{{ item.dataTitle }}</md-table-cell>
                        <md-table-cell md-label="Language">{{
                            item.subLang ? item.subLang.eng ? 'KO/EN' : 'KO' : ""
                        }}</md-table-cell>
                        <md-table-cell md-label="Edit">
                            <a href="javascript:void(0);" @click="goToEdit(item.sceneId, item.number)" class="editBtn">edit</a>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div>
        <div v-if="alertData" class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>cast</md-icon>
                </div>
                <h4 class="title">Edit KF Conference Pop-up Info</h4>
                </md-card-header>
                <md-card-content>               
                    <div class="md-layout">
                        <div class="md-layout-item md-size-100">
                            <md-switch class="cast-controller" v-model="alertData.status">Conference Pop-up on/off</md-switch>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Title(KO)</label>
                                <md-input v-model="alertData.title"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-50">
                            <md-field>
                                <label>Title(EN)</label>
                                <md-input v-model="alertData.subLang.eng.title"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-medium-size-30">
                            <md-field>
                                <label>Presenter Name</label>
                                <md-input v-model="alertData.metaData.presenter"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-medium-size-30">
                            <md-field>
                                <label>Group</label>
                                <md-input v-model="alertData.metaData.group"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item md-medium-size-30">
                            <md-datepicker v-model="alertData.date">
                                <label>Select date</label>
                            </md-datepicker>
                        </div>
                        <div class="md-layout-item md-medium-size-70">
                            <md-field>
                                <label>Time(ex: 05:00)</label>
                                <md-input v-model="alertData.time"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="edit-form md-layout md-alignment-center-right">
                        <div class="md-layout-item md-size-10">
                            <md-button @click="updateAlert" class="md-dense md-raised md-primary">저장</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>cast</md-icon>
                </div>
                <h4 class="title">Conference Speaker Controller</h4>
                </md-card-header>
                <md-card-content>
                    <md-switch v-model="isOnAir" @change="connectControl">Conference on/off</md-switch>
                    <div class="control-box">
                        <md-switch class="cast-controller" v-model="isCameraOn" @change="cameraControl" :disabled="disabledList.camera">Camera on/off</md-switch>
                        <md-switch class="cast-controller" v-model="isMicOn" @change="micControl" :disabled="disabledList.mic">MIC on/off</md-switch>
                        <md-switch class="cast-controller" v-model="isScreenOn" @change="screenControl" :disabled="disabledList.screen">Screen on/off</md-switch>
                        <md-switch class="cast-controller" v-model="isScreenAudioOn" @change="screenAudioControl" :disabled="disabledList.screenAudio">Screen Audio on/off</md-switch>
                    </div>
                    <div class="md-layout">
                        <div class="md-layout-item md-size-30 conferece-speaker-wrap">
                            <p>My Camera</p>
                            <video id="local_camera" autoplay muted controls></video>
                        </div>
                        <div class="md-layout-item md-size-30 conferece-speaker-wrap">
                            <div class="screen-control-box">
                                <p>Screen</p>
                                <md-button v-if="isScreenOn" class="md-raised ml-1" @click="replaceScreen">화면 전환</md-button>
                            </div>
                            <video id="local_video" autoplay muted controls></video>
                        </div>
                        <div class="md-layout-item md-size-40 conferece-speaker-wrap">
                            <p>Chat</p>
                            <Chat
                                ref="chatComponent"
                                id="chatBox"
                            />
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</div>
</template>

<script>
import SocketManager from '@/socketManager/socketManager';
const mediasoup = require('mediasoup-client');
import socketClient from 'socket.io-client';
import config from '@/config/config';
import { socketPromise } from '@/commons/socket.io-promise';
import Chat from "@/components/Chat/Chat.vue";
import axios from "axios";
import api from "@/commons/apiUrl";

export default {
    components: {
        Chat
    },
    data() {
        return {
            contents: {},
            isOnAir: false,
            isScreenOn: false,
            isScreenAudioOn: false,
            isCameraOn: false,
            disabledList: {
                camera: true,
                mic: true,
                screen: true,
                screenAudio: true
            },
            isMicOn: false,
            camStream: null,
            screenStream: null,
            socket: null,
            device: null,
            producer: null,
            cameraProducer: null,
            audioProducer: null,
            screenProducer: null,
            screenAudioProducer: null,
            userInfo: {
                _id: "kf-presenter",
                id: "kf-presenter",
                nickname: "Presenter",
            },
            type: "video",
            transport: null,
            alertData: null
        };
    },
    async created() {
        const res = await axios.get(api.GET_DATA("conference"));
        if(!res.data.success) {
            return;
        }

        this.contents = res.data.data.contents;

        const alertRes = await axios.get(api.GET_CONFERENCE_ALERT());
        if(!alertRes.data.success) {
            return;
        }

        this.alertData = alertRes.data.data;
    },
    methods: {
        async updateAlert() {
            let pattern = /^([1-9]|[01][0-9]|2[0-3]):([0-5][0-9])$/;
            if(!pattern.test(this.alertData.time)) {
                this.$store.dispatch("alerts/error", "시간 포맷을 맞춰주세요(ex: 05:00)");
                
                return;
            }

            const data = new FormData();
            
            this.alertData._id ? data.append("alertId", this.alertData._id) : null;
            this.alertData.subLang.eng.title ? data.append("engTitle", this.alertData.subLang.eng.title) : null;
            this.alertData.title ? data.append("title", this.alertData.title) : null;
            this.alertData.date ? data.append("date", this.alertData.date) : null;
            this.alertData.time ? data.append("time", this.alertData.time) : null;
            data.append("status", this.alertData.status);
            data.append("presenter", this.alertData.metaData.presenter);
            data.append("group", this.alertData.metaData.group);

            const res = await axios.post(api.UPDATE_CONFERENCE_ALERT(),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "팝업 저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "팝업 저장 성공");
        },
        async connectControl() {
            if(!this.isOnAir) {
                await this.socket.request("changePresentStatus", {isOnAir: false});

                this.socket.close();
                this.socket = null;
                
                this.isCameraOn = false;
                this.isMicOn = false;
                this.isScreenOn = false;
                this.isScreenAudioOn = false;

                const type = ["camera", "audio", "screen", "screenAudio"];
                type.forEach((item) => {
                    this.closeProduce(item);
                });

                SocketManager.getInstance().closeChatSocket();

                return;
            }

            await this.connect();
            await this.socket.request("changePresentStatus", {isOnAir: true});

            await this.$refs.chatComponent.setUserInfo(this.userInfo);
            await this.$refs.chatComponent.connectChatSocket("1", "event");
        },
        async cameraControl() {
            if(!this.isOnAir && !this.socket) {
                alert("컨퍼런스 기능을 먼저 켜주세요.");
                this.isCameraOn = false;

                return;
            }

            if(!this.isCameraOn) {
                this.pauseProduce("camera");

                return;
            }

            if(this.cameraProducer) {
                this.resumeProduce("camera");

                return;
            }

            this.type = "video";
            await this.publish(true, 'video');
        },
        async micControl() {
            if(!this.isOnAir && !this.socket) {
                alert("컨퍼런스 기능을 먼저 켜주세요.");
                this.isMicOn = false;

                return;
            }
            if(!this.isCameraOn) {
                alert("카메라 기능을 먼저 켜주세요.");
                this.isMicOn = false;

                return;
            }

            if(!this.isMicOn) {
                this.pauseProduce("audio");

                return;
            }

            if(this.audioProducer) {
                this.resumeProduce("audio");

                return;
            }

            this.type = "audio";
            await this.publish(true, 'audio');
        },
        async screenControl() {
            if(!this.isOnAir && !this.socket) {
                alert("컨퍼런스 기능을 먼저 켜주세요.");
                this.isCameraOn = false;

                return;
            }

            if(!this.isScreenOn) {
                this.pauseProduce("screen");

                return;
            }

            if(this.screenProducer) {
                this.resumeProduce("screen");

                return;
            }

            this.type = "screen";
            await this.publish(false, 'screen');
        },
        async screenAudioControl() {
            if(!this.isOnAir && !this.socket) {
                alert("컨퍼런스 기능을 먼저 켜주세요.");
                this.isMicOn = false;

                return;
            }
            if(!this.isScreenOn) {
                alert("화면공유 기능을 먼저 켜주세요.");
                this.isMicOn = false;

                return;
            }

            if(!this.isScreenAudioOn) {
                this.pauseProduce("screenAudio");

                return;
            }

            if(this.screenAudioProducer) {
                this.resumeProduce("screenAudio");

                return;
            }

            this.type = "screenAudio";
            await this.publish(false, 'screenAudio');
        },
        closeProduce(type) {
            if(type === "camera" && this.cameraProducer) {
                this.cameraProducer.close();
                this.cameraProducer = null;
                this.camStream.getTracks().forEach(track => {
                    track.stop();
                });
                this.camStream = null;
            }
            else if(type === "audio" && this.audioProducer) {
                this.audioProducer.close();
                this.audioProducer = null;
                this.camStream = null;
            }
            else if(type === "screen" && this.screenProducer) {
                this.screenProducer.close();
                this.screenProducer = null;
                this.screenStream.getTracks().forEach(track => {
                    track.stop();
                });
                this.screenStream = null;
            }
            else if(type === "screenAudio" && this.screenAudioProducer){
                this.screenAudioProducer.close();
                this.screenAudioProducer = null;
                this.screenStream = null;
            }
        },
        pauseProduce(type) {
            if(type === "camera") {
                this.cameraProducer.pause();
            }
            else if(type === "audio") {
                this.audioProducer.pause();
            }
            else if(type === "screen") {
                this.screenProducer.pause();
            }
            else {
                this.screenAudioProducer.pause();
            }
        },
        resumeProduce(type) {
            if(type === "camera") {
                this.cameraProducer.resume();
            }
            else if(type === "audio") {
                this.audioProducer.resume();
            }
            else if(type === "screen") {
                this.screenProducer.resume();
            }
            else {
                this.screenAudioProducer.resume();
            }
        },
        async replaceScreen() {
            this.screenStream.getTracks().forEach(track => {
                track.stop();
            });
            this.screenStream = null;

            const stream = await this.getUserMedia(null, false);
            await this.screenProducer.replaceTrack({ track: stream.getVideoTracks()[0] });

            if(this.screenAudioProducer) {
                await this.screenAudioProducer.replaceTrack({ track: stream.getAudioTracks()[0] });
            }

            document.querySelector('#local_video').srcObject = stream;
        },
        async connect() {
            const opts = {
                path: '/server',
                transports: ['websocket'],
            };

            try {
                const serverUrl = `${process.env.VUE_APP_MEDIA_BASE_URL}:${config.listenPort}`;
                this.socket = socketClient(serverUrl, opts);
                this.socket.request = socketPromise(this.socket);

                this.socket.on('connect', async () => {
                    const data = await this.socket.request('getRouterRtpCapabilities');
                    await this.loadDevice(data);

                    this.disabledList.camera = false;
                    this.disabledList.screen = false;
                });

                this.socket.on('disconnect', () => {
                    console.log("disconnect!!!!!")
                });

                this.socket.on('connect_error', (error) => {
                    alert("컨퍼런스 소켓 연결 실패");
                    this.isOnAir = false;
                    this.connectControl();

                    console.error('could not connect to %s%s (%s)', serverUrl, opts.path, error.message);
                });

                this.socket.on('newProducer', () => {
                    // $fsSubscribe.disabled = false;
                });
            }
            catch(e) {
                alert("컨퍼런스 소켓 연결 실패");
            }
        },
        async loadDevice(routerRtpCapabilities) {
            try {
                this.device = new mediasoup.Device();
            } catch (error) {
                if (error.name === 'UnsupportedError') {
                console.error('browser not supported');
                }
            }
            await this.device.load({ routerRtpCapabilities });
        },
        async publish(webcam=false, type="video") {
            if(this.type === "audio" && !this.camStream) {
                alert("카메라를 먼저 공유해 주세요.");

                return;
            }

            if(this.type === "screenAudio" && !this.screenStream) {
                alert("화면을 먼저 공유해 주세요.");

                return;
            }

            const isWebcam = webcam;
            const data = await this.socket.request('createProducerTransport', {
                forceTcp: false,
                rtpCapabilities: this.device.rtpCapabilities,
            });
            
            if (data.error) {
                console.error(data.error);
                return;
            }

            const transport = this.device.createSendTransport(data);

            transport.on('connect', async ({ dtlsParameters }, callback, errback) => {
                this.socket.request('connectProducerTransport', { dtlsParameters })
                .then(callback)
                .catch(errback);
            });

            transport.on('produce', async ({ kind, rtpParameters }, callback, errback) => {
                try {
                    const { id } = await this.socket.request('produce', {
                        transportId: transport.id,
                        kind,
                        rtpParameters,
                        type: type
                    });

                    callback({ id });
                } catch (err) {
                    errback(err);
                }
            });

            transport.on('connectionstatechange', (state) => {
                switch (state) {
                    case 'connecting':
                        console.log('publishing...');
                    break;

                    case 'connected':
                        console.log(type, ' published');
                    break;

                    case 'failed':
                        transport.close();
                    break;

                    default: break;
                }
            });

            let stream;
            try {
                stream = await this.getUserMedia(transport, isWebcam);
                let params;
                if(type === "audio") {
                    params = { track: stream.getAudioTracks()[0] };
                    this.audioProducer = await transport.produce(params);
                }
                else if(type === "video") {
                    params = { track: stream.getVideoTracks()[0] };
                    this.cameraProducer = await transport.produce(params);

                    this.disabledList.mic = false;
                }
                else if(type === "screen") {
                    params = { track: stream.getVideoTracks()[0] };
                    this.screenProducer = await transport.produce(params);

                    this.disabledList.screenAudio = false;
                }
                else {
                    params = { track: stream.getAudioTracks()[0] };
                    this.screenAudioProducer = await transport.produce(params);
                }
            } catch (err) {
                console.log(err)
                console.log("failed")
            }
        },
        async getUserMedia(transport, isWebcam) {
            console.log(this.camStream, this.screenStream)

            if (!this.device.canProduce('video')) {
                console.error('cannot produce video');
                return;
            }

            try {
                if(isWebcam) {
                    if(this.camStream) {
                        console.log("camera exists");
                        return this.camStream;
                    }

                    this.camStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

                    document.querySelector('#local_camera').srcObject = this.camStream;

                    return this.camStream;
                }

                if(this.screenStream) {
                    console.log("screen exists@!");
                    return this.screenStream;
                }
                
                this.screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true  });

                document.querySelector('#local_video').srcObject = this.screenStream;
                
                return this.screenStream;
            } catch (err) {
                console.error('getUserMedia failed:', err.message);
                throw err;
            }
        },
        goToEdit(sceneId, number) {
            this.$router.push(`edit/scene/${sceneId}/${number}`);
        }
    }
}
</script>

<style scoped>
.md-switch {
    display: flex;
}
.control-box {
    display: flex;
}
.screen-control-box {
    display: flex;   
}
.ml-1 {
    margin-left: 1rem;
}
.item-thumb {
    max-width: 50px;
}
.conferece-speaker-wrap {
    border: solid 1px black;
}
.conferece-speaker-wrap > video {
    width: 100%;
}
</style>
<style>
.md-switch-label {
    color: black !important;
}
</style>
<template @keyup.enter="activeChat">
  <div>
    <beautiful-chat
      :participants="participants"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="false"
      :showFile="false"
      :showEdition="true"
      :showDeletion="true"
      :deletionConfirmation="true"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="false"
      :showCloseButton="true"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :disableUserListToggle="false"
      :messageStyling="messageStyling"
      :title="headerTitle"
      @edit="editMessage" >
        
        <template v-slot:user-avatar="{ message, user }">
            <div style="color: black; font-size: 10px; line-height:30px; text-align:center; width: 25px !important; height: 25px !important; min-width: 30px;min-height: 30px;margin: 5px; font-weight:bolder" v-if="message.type === 'text' && user && user.name" v-tooltip="user.name">
                {{user.name.length > 3 ? user.name[0] + user.name[1] + ".." : user.name}}
            </div>
        </template>

    </beautiful-chat>
  </div>
</template>

<script>
import { io } from 'socket-new';
import moment from 'moment';
import SocketManager from '@/socketManager/socketManager';

export default {
    name: 'app',
    props: ["namespace"],
    data() {
        return {
            chatType: "all",
            group: this.namespace,
            roomName: "",
            userInfo: {},
            // modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
            defaultImg: "https://krverse.s3.ap-northeast-2.amazonaws.com/krverse/default_image.png",
            // defaultProfile: "https://360hexaworld.blob.core.windows.net/img-files-dev/default_profile_2.png",
            participants: [], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
            headerTitle: "전체 채팅",
            titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
            messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
            newMessagesCount: 0,
            isChatOpen: true, // to determine whether the chat window should be open or closed
            showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
            colors: {
                header: {
                    // bg: '#301934',
                    text: '#ffffff'
                },
                launcher: {
                    bg: '#4e8cff'
                },
                messageList: {
                    // bg: '#ffffff'
                },
                sentMessage: {
                    // bg: '#301934',
                    bg: "transparent",
                    text: 'black'
                },
                receivedMessage: {
                    // bg: '#eaeaea',
                    bg: "transparent",
                    text: 'black'
                },
                userInput: {
                    // bg: '#f4f7f9',
                    text: '#565867'
                }
            }, // specifies the color scheme for the component
            alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
            messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
            socket: null,
        }
    },
    async mounted() {
        document.documentElement.addEventListener("keyup", function(e) {
            if(e.key === "Enter") {
                const chatForm = document.getElementsByClassName("sc-user-input");
                const chatInput = document.getElementsByClassName("sc-user-input--text");

                chatForm[0].classList.add("active");
                chatInput[0].focus();
            }
            else if(e.key === "Escape") {
                const chatForm = document.getElementsByClassName("sc-user-input");
                const chatInput = document.getElementsByClassName("sc-user-input--text");

                chatForm[0].classList.remove("active");
                chatInput[0].blur();
            }
        }, true);
    },
    methods: {
        connectChatSocket(channel, roomName, chatType="all", options={}) {
            this.chatType = chatType;
            this.roomName = roomName;

            const manager = SocketManager.getInstance();
            manager.setChatSocket(io(process.env.VUE_APP_CHAT_SERVER.concat("/", channel)));

            this.socket = manager.chatSocket;
            this.socket.emit('joinRoom', { userId: this.userInfo._id, username: this.userInfo.nickname, room: roomName, chatType: chatType, options: options });

            this.socket.on('message', (message) => {
                this.receiveMessage(message);
            });

            this.socket.on('roomUsers', (user) => {
                user.users.map(item => {
                    item.imageUrl = this.defaultProfile;
                });

                this.participants = user.users;

                if(chatType === "all") {
                    this.headerTitle = `채팅(${user.userCount}/100)`;
                }
                else if(chatType === "oneOnOne") {
                    this.headerTitle = `${options.chatRoomTitle}(${user.userCount}/2)`;
                    this.showCam = true;
                    this.beforeSelected = "cam";
                }
                else if(chatType === "meetingRoom") {
                    this.headerTitle = `${options.chatRoomTitle}(${user.userCount}/4)`;
                    this.showCam = true;
                    this.beforeSelected = "cam";
                }

                // 방 인원 갱신
                console.log(user)
                this.$emit("userMonitoring", user);
            });

            this.socket.on("maxUser", () => {
                alert("방 인원이 모두 찼습니다.\n대기 후 입장 부탁드립니다.");
                this.socket.close();
            });

            this.socket.on("requestFromSomeone", (userInfo) => {
                console.log("request from someone : ", userInfo);
                this.$emit("alertFromChild", userInfo);
            });

            this.socket.on("rejectedRequest", () => {
                console.log("1:1 대화 거절 당함");
                this.$emit("rejectedAlertFromChild");
            });

            this.socket.on("acceptedRequest", (toUserInfo) => {
                this.$emit("acceptedAlertFromChild", toUserInfo);
            });

            this.socket.on("unKnownUser", () => {
                console.log("알 수 없는 사용자 입니다.");
                this.$emit("unKnownFromChild")
            });

            this.socket.on("userCamStatus", ({isEnabled, userId, isExit}) => {
                this.$emit("userCamFromChild", {isEnabled, userId, isExit});
            });

            this.socket.on("oneOnOneUserCamStatus", ({isEnabled, userId, isExit}) => {
                this.$emit("oneOnOneUserCamFromChild", {isEnabled, userId, isExit});
            });

            this.socket.on("getRoomInfo", ({count, roomName, users}) => {
                this.$emit("meetingUserCountFromChild", {count, roomName, users});
            });

            this.socket.on("receiveObjectRotate", (rotate) => {
                this.$emit("objectRotateFromChild", rotate);
            });

            this.socket.on("getAllMeetingRoomInfo", (result) => {
                this.$emit("updateMeetingRoomMembers", result);
            });
        },
        sendObjectRotate(rotate) {
            this.socket.emit("objectRotate", rotate);
        },
        setUserInfo(userInfo) {
            this.userInfo = userInfo;
        },
        requestToSomeone(to, from) {
            this.socket.emit("requestToSomeone", {to: to, from: from});
        },
        rejectToRequest(userInfo) {
            this.socket.emit("rejectRequest", userInfo);
        },
        acceptMsgToFromUser(fromUserInfo, toUserInfo) {
            this.socket.emit("oneOnOneAcepted", {from: fromUserInfo, to: toUserInfo});
        },
        userCam(isEnabled, userId, isExit=false) {
            this.socket.emit("userCam", {isEnabled: isEnabled, userId: userId, isExit: isExit});
        },
        oneOnOneUserCam(isEnabled, userId, isExit=false) {
            this.socket.emit("oneOnOneUserCam", {isEnabled: isEnabled, userId: userId, isExit: isExit});
        },
        checkMeetingRoomCount(roomName) {
            this.socket.emit("checkRoomCountByName", roomName);
        },
        checkAllMeetingRoomInfo(roomList) {
            this.socket.emit("checkMeetingRoomInfoByArr", roomList);
        },
        setMeetingRoomSeat(seat) {
            this.socket.emit("setMeetingRoomSeat", seat);
        },
        closeSocket() {
            this.socket.close();
        },
        receiveMessage(message) {
            // this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
            // this.options[this.chatIndex].badge = this.isChatOpen ? this.options[this.chatIndex].badge : this.options[this.chatIndex].badge + 1;
            this.messageList = [ ...this.messageList, message ];
        },
        sendMessage (text) {
            if (text.length > 0) {
                // this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
                this.options[this.chatIndex].badge = this.isChatOpen ? this.options[this.chatIndex].badge : this.options[this.chatIndex].badge + 1;
                this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
            }
        },
        onMessageWasSent (message) {
            // called when the user sends a message
            // m = moment().tz("Asia/Seoul");
            // console.log(moment().tz("Asia/Seoul").format('h:mm a'))
            message.data.meta = moment().format('h:mm a');
            this.messageList = [ ...this.messageList, message ];
            this.socket.emit('chatMessage', { room: this.roomName, username: this.userInfo.nickname, text: message.data.text });
        },
        openChat () {
            // called when the user clicks on the fab button to open the chat
            this.isChatOpen = true
            // this.newMessagesCount = 0
            this.options[this.chatIndex].badge = 0;
        },
        closeChat () {
            // called when the user clicks on the botton to close the chat
            this.isChatOpen = false
        },
        handleScrollToTop () {
            // called when the user scrolls message list to top
            // leverage pagination for loading another page of messages
        },
        handleOnType () {
            console.log('Emit typing event')
        },
        editMessage(message){
            const m = this.messageList.find(m=>m.id === message.id);
            m.isEdited = true;
            m.data.text = message.data.text;
        },
        activeChat() {
            console.log("enter!!!!")
        }
    }
}
</script>

<style>
.sc-message {
    width: 100%;
    margin: 0px;
}
.sc-message-list::-webkit-scrollbar {
    display: none;
}
.sc-message-list:hover {
    display: block;
}
.sc-message-list {
    background-color: transparent !important;
}
.sc-chat-window[data-v-329daf46]:hover {
    box-shadow: 0 7px 40px 2px rgb(148 149 150 / 100%) !important;
}
.sc-chat-window[data-v-329daf46] {
    box-shadow: none !important;
}
.sc-chat-window[data-v-08d4c038] {
    box-shadow: none !important;
    position: static !important;
    width: 100%;
    height: 500px;
    background-color: rgba(227, 226, 226, 0.8) !important;
    /* border: solid black 1px; */
}
.sc-message--content.sent .sc-message--text {
    color: black !important;
    margin-right: 15px;
}
.sc-message--meta {
    color: black !important;
    text-align: right;
    /* color: white !important; */
}
.sc-message--system {
    color: black !important;
    font-weight: normal !important;
    font-style: initial !important;
}
.sc-message--system > p {
    display: none;
}
.sc-header {
    display: none !important;
}
.sc-header--close-button {
    display: none;
}
.sc-user-input {
    border-radius: 10px;
    background-color: transparent;
}
.sc-user-input--text {
    text-align: left;
}
.sc-message--text .sc-message--text-content[data-v-f74c2934] {
    font-weight: normal;
    color: black !important;
    margin-bottom: 0px;
}
.sc-message--text-content, .sc-message--meta {
    margin: 0px !important;
}
.sc-message--text {
    /* color: white !important; */
    border: solid rgb(220,220,220,0.2) 1px;
    border-radius: 15px;
    background-color: rgb(0,0,0,0.1) !important;
}
.sc-message--system[data-v-0c1d322a] {
    font-weight: 900 !important;
    color: black !important;
}
.sc-header--title.enabled[data-v-f747f92a] {
    pointer-events: none;
}
.received {
    display: block !important;
    /* width: 80% !important; */
    word-wrap: break-word;
    white-space: -moz-pre-wrap;
    white-space: pre-wrap; 
    margin-left: 15px;
    width: fit-content;
}
.sc-message--content.received .sc-message--text {
    color: black !important;
    background-color: transparent !important;
    border: solid black 1px;

}
/* bottom style */
@media (min-width: 1281px) {
    .sc-chat-window[data-v-329daf46] {
        bottom: 5% !important;
        left: 1% !important;
        /* background-color: transparent; */
        height: 40%;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .sc-chat-window[data-v-329daf46] {
        bottom: 100px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    
}

@media (min-width: 481px) and (max-width: 767px) {
    
}

@media (max-width: 481px) {
    .sc-chat-window[data-v-329daf46] {
        width: 45% !important;
        /* height: calc(100% - 70px) !important; */
        height: 40% !important;
        max-height: 100% !important;
        left: 0 !important;
        bottom: 0px !important;
        border-radius: 0 !important;
        background-color: transparent;
    }
}
</style>